@import 'variables';

.layout-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
    transition: margin-left 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-content-main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 1rem 2rem;
    overflow: auto;
    min-height: calc(100vh - 11.125rem);
}

.layout-content {
    display: flex;
    flex-direction: row;
    padding-top: 7.125rem;
    overflow: auto;
    margin-bottom: auto;

    ul {
        margin-bottom: 0;
    }
}

.layout-breadcrumb {
    background: var(--surface-100);
    border-bottom: 1px solid var(--surface-border);
    position: fixed;
    top: 4rem;
    width: 100%;
    min-height: 3.3rem;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    z-index: 1002;
}
