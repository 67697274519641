.layout-static, .layout-overlay {
    .layout-menu .layout-root-menuitem > a.link-item {
        display: flex;
        align-items: center;
        position: relative;
        padding: 0.5rem;
        font-size: 1rem;
        border-radius: var(--border-radius);
        outline: 0 none;
        cursor: pointer;
        transition:
                background-color var(--transition-duration),
                box-shadow var(--transition-duration);

        .layout-menuitem-icon {
            margin-right: 0.5rem;
        }

        .layout-submenu-toggler {
            font-size: 75%;
            margin-left: auto;
            transition: transform var(--transition-duration);
        }

        &.active-route {
            font-weight: 700;
        }
    }
}
