// screen size breakpoint (leacy)
$app-desktop-screen-size: 992px;
$app-mobile-screen-size: 991px;

// screen size breakpoints
$app-mobile-s-screen-size: 320px;
$app-mobile-m-screen-size: 375px;
$app-mobile-l-screen-size: 435px;
$app-tablet-screen-size:   768px;
$app-laptop-screen-size:   1024px;
$app-laptop-l-screen-size: 1440px;
$app-4k-screen-size: 2560px;


// modal sizes
$app-max-modal-width: 1600px;

:root {
    // font-size
    --app-general-font-size-xx-large: 3rem;
    --app-general-font-size-x-large: 2rem;
    --app-general-font-size-large: 1.5rem;
    --app-general-font-size: 1rem;
    --app-general-font-size-small: 0.875rem;
    --app-general-font-size-x-small: 0.75rem;
    --app-general-font-size-xx-small: 0.5rem;

    // colors
    --app-panel-bg-color: var(--surface-0);

    // spacing
    --app-general-spacing-large: 2rem;
    --app-general-spacing-medium: 1.5rem;
    --app-general-spacing: 1rem;
    --app-general-spacing-small: 0.5rem;
    --app-general-spacing-x-small: 0.25rem;
    --app-general-spacing-xx-small: 0.125rem;
    --app-general-spacing-xxx-small: 0.0625rem;

    // borders
    --app-general-border-radius: 4px;
    --app-general-border-color: var(--surface-border);

    // hover
    --app-general-hover-color: var(--surface-hover);

    // screen size breakpoint
    --app-screen-size-breakpoint: #{$app-desktop-screen-size};

    // gridster
    --app-gridster-edit-bg: var(--surface-500);

    // gender colors
    --app-gender-default-bg-color: var(--surface-500);
    --app-gender-male-bg-color: #047F94;
    --app-gender-female-bg-color: #A5326B;

    // scheduler
    --app-scheduler-tag-bg-color: #EFF6FF;
    --app-scheduler-tag-fg-color: #1E3A8A;
    --app-scheduler-event-default-bg-color: var(--surface-400);
}
