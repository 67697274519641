.layout-sidebar-indigo {
  --d-sidebar-bg-color: #3f51b5;
  --d-sidebar-bg-color-alt: #2c397f;
  --d-sidebar-border: 0 none;
  --d-app-name-color: #ffffff;
  --d-menu-separator-border: 1px solid rgba(255, 255, 255, 0.2);
  --d-menuitem-root-text-color: rgba(255, 255, 255, 0.6);
  --d-menuitem-text-color: rgba(255, 255, 255, 0.8);
  --d-menuitem-hover-bg: rgba(255, 255, 255, 0.1);
  --d-menuitem-active-bg: rgba(255, 255, 255, 0.1);
  --d-menuitem-text-active-color: #ffffff;
  --d-menuitem-focus-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
