.layout-sidebar {
    width: 16rem;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    box-shadow: $sidebarShadow;
    display: flex;
    flex-direction: column;
    user-select: none;
    transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);

    .logo {
        text-align: center;
        display: flex;
        justify-content: center;
        outline: 0 none;
        padding: 0.575rem 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        transition:
            background-color var(--transition-duration),
            box-shadow var(--transition-duration);
        border-bottom: var(--d-menu-separator-border);
    }

    .logo-image {
        width: 3rem;
        height: 2rem;
        vertical-align: middle;
        margin-right: 0.5rem;
    }

    .app-name {
        vertical-align: middle;
        font-size: 1.5rem;
        letter-spacing: 0.2px;
    }

    .pre-app-name {
        display: none;
    }
}

.layout-menu-container {
    overflow: auto;
    flex: 1;
    padding: 1rem;
}

.layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .menu-separator {
        margin: 1rem 0;
    }

    .layout-root-menuitem {
        > .layout-menuitem-root-text {
            font-size: 0.857rem;
            text-transform: uppercase;
            font-weight: 700;
            padding: 1rem 0 0.5rem 0;
        }

        > a {
            display: none;
        }

        .layout-menu-tooltip {
            display: none;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        a {
            display: flex;
            align-items: center;
            position: relative;
            padding: 0.5rem;
            font-size: 1rem;
            border-radius: var(--border-radius);
            outline: 0 none;
            cursor: pointer;
            transition:
                background-color var(--transition-duration),
                box-shadow var(--transition-duration);

            .layout-menuitem-icon {
                margin-right: 0.5rem;
            }

            .layout-submenu-toggler {
                font-size: 75%;
                margin-left: auto;
                transition: transform var(--transition-duration);
            }

            &.active-route {
                font-weight: 700;
            }
        }

        li {
            &.active-menuitem {
                border-radius: var(--border-radius);

                > a {
                    .layout-submenu-toggler {
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        ul {
            overflow: hidden;
            border-radius: var(--border-radius);

            li {
                a {
                    padding-left: 1.5rem;
                }

                li {
                    a {
                        padding-left: 2.5rem;
                    }

                    li {
                        a {
                            padding-left: 3.5rem;
                        }

                        li {
                            a {
                                padding-left: 4.5rem;
                            }

                            li {
                                a {
                                    padding-left: 5.5rem;
                                }

                                li {
                                    a {
                                        padding-left: 6.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
