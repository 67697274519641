@import 'assets/flags/flags.scss';

$gutter: 1rem; //for primeflex grid system

/* Layout */
@import 'assets/styles/layout.scss';

/* PrimeNG */
@import 'primeng/resources/primeng.min.css';
@import 'primeflex/primeflex.scss';
@import 'primeicons/primeicons.css';

/* Syncfusion */
@import '@syncfusion/ej2-fluent2-theme/styles/fluent2.css';

/* Quill Text Editor for p-editor */
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
    font-size: 0.75rem;
}

.p-tooltip {
    width: initial;
    min-width: 75px;
    max-width: initial;
}

.opensans {
    font-family: 'Open Sans', sans-serif;
}

:root {
    --font-family: 'Open Sans';
}

:root {
    --primary-color: #2196f3;
    --primary-text-color: #ffffff;
    --highlight-bg: #e3f2fd;
    --highlight-text-color: #495057;
    --primary-light-color-percentage: 40%;
    --sidebar-size: 18rem;
    --small-sidebar-size: 4rem;
    --header-size: 4rem;
    --topbar-size: 3rem;
    --footer-size: 3rem;
    --header-menu-width: 35rem;
    --sidebar-bg-color: #3a3633;
    --sidebar-text-color: #c0bbb7;
    --sidebar-border-color: #525151;
    --panelmenu-hover-color: #44403d;
    --panelmenu-highlight-color: rgb(78, 74, 71);
    --message-table-stripe-color: #dbdbdb;
    --message-table-hover-color: #cbcbcb;
    --header-bg-color: #f3f3f3;
    --header-text-color: #757575;
    --header-button-border-color: #bfbfbf;
    --header-button-bg-color: #f8f8f8;
    --header-button-hover-color: #d8d8d8;
    --body-bg-color: #f3f3f3;
    --body-text-color: #757575;
    --save-message-button-color: #2094b8;
    --save-message-button-color-hover: #1c84a3;
    --discard-message-button-color: #ff0000;
    --discard-message-button-color-hover: #d40000;
    --send-message-button-color: #3b82f6;
    --send-message-button-color-hover: #3070d8;
    --databoxes-list-stripe-color: #f0f0f0;
    --language-change-button-color: #0000ff;
    --databox-remove-button-color: #ff0000;
    --databox-edit-button-color: #0000ff;
    --databox-create-button-bg-color: #008000;
    --databox-create-button-bg-hover-color: #006400;
    --databox-create-button-color: #ffffff;
    --login-page-bg-color: #ffffff;
    --attachement-used-capacity-bar-color: #739e73;
    --selected-recipient-background-color: #3b82f6;

    --red-label: red;
    --green-label: green;
    --blue-label: blue;
    --yellow-label: yellow;
    --orange-label: orange;
    --pink-label: pink;
    --purple-label: purple;
}

.header-button {
    border: 1px solid var(--header-button-border-color);
    background-color: var(--header-button-bg-color);
    color: var(--header-text-color);
    padding: 5px;
}

body {
    font-family: var(--font-family);
    border: 0;
    margin: 0;
}

html {
    font-size: var(--body-font-size);
}

.p-button,
.p-autocomplete
.p-autocomplete-multiple-container
.p-autocomplete-input-token
input,
.p-component,
.p-datatable .p-datatable-thead > tr > th {
    font-size: var(--body-font-size);
}

.p-inputgroup input:first-child,
.p-inputgroup button:last-child,
.p-inputtext,
.p-button,
.p-multiselect,
.p-progressbar,
.p-checkbox .p-checkbox-box,
.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-panelmenu .p-panelmenu-header.p-highlight > a {
    border-radius: 0;
}

.p-dialog {
    max-width: 100vw;
}

.dialogButtons {
    @include app-flex-row;
    @include app-justify-content(flex-end);
    gap: var(--app-general-spacing-small);

    &-actionButtons {
        gap: var(--app-general-spacing-large);
    }

    &-fullWidth {
        @include app-justify-content(space-between);
    }
}

.button-label-bold .p-button-label {
    font-weight: bold;
}

.p-tabview {
    background: #ffffff;

    .p-tabview-nav-container {
        padding: 0.5rem 1rem;

        .p-tabview-nav {
            overflow-x: auto;
            overflow-y: hidden;
            border: 0;

            li {
                border-bottom: 0.2rem solid #dee2e6;
            }

            li:hover {
                border-bottom: 0.2rem solid #6c757d;
            }

            .p-highlight {
                border-bottom: 0.2rem solid var(--primary-color);
            }

            .p-tabview-nav-link {
                padding: 0.6rem 1.6rem;
            }
        }
    }
}

.fr-2 {
    grid-column: span 2;
}

.fr-3 {
    grid-column: span 3;
}

.fr-4 {
    grid-column: span 4;
}

.fr-5 {
    grid-column: span 5;
}

.fr-6 {
    grid-column: span 6;
}

.fr-8 {
    grid-column: span 8;
}

.fr-9 {
    grid-column: span 9;
}

.fr-10 {
    grid-column: span 10;
}

.fr-12 {
    grid-column: span 12;
}

.fr-full {
    grid-column: 1/-1;
}

.fh-2 {
    grid-row: span 2;
}

.fh-3 {
    grid-row: span 3;
}

.fh-4 {
    grid-row: span 4;
}

.fh-5 {
    grid-row: span 5;
}

.fh-full {
    grid-row: 1 / -1;
}

.row-2nd {
    grid-row: 2 / 3;
}

.col-2nd {
    grid-column: 2 / 3;
}

.col-3rd {
    grid-column: 3 / 4;
}

.grid-1col {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    &_auto {
        display: grid;
        grid-template-columns: repeat(1, auto);
    }
}

.grid-2col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &_auto {
        display: grid;
        grid-template-columns: repeat(2, auto);
    }

    &_fit30 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    }
}

.grid-3col {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    &_auto {
        display: grid;
        grid-template-columns: repeat(3, auto);
    }

    &_min15 {
        display: grid;
        grid-template-columns: repeat(3, minmax(15rem, 1fr));
    }
}

.grid-4col {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    &_auto {
        display: grid;
        grid-template-columns: repeat(4, auto);
    }

    &_min15 {
        display: grid;
        grid-template-columns: repeat(4, minmax(15rem, 1fr));
    }
}

.grid-5col {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    &_auto {
        display: grid;
        grid-template-columns: repeat(5, auto);
    }
}

.grid-6col {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    &_auto {
        display: grid;
        grid-template-columns: repeat(6, auto);
    }
}

.grid-7col {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    &_auto {
        display: grid;
        grid-template-columns: repeat(7, auto);
    }
}

.grid-10col {
    display: grid;
    grid-template-columns: repeat(10, 1fr);

    &_auto {
        display: grid;
        grid-template-columns: repeat(10, auto);
    }
}

.grid-12col {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    &_auto {
        display: grid;
        grid-template-columns: repeat(12, auto);
    }
}

.input-grid-gaps {
    gap: 1em;
    align-items: flex-start;

    &-small {
        gap: var(--app-general-spacing-small);
    }

    &-x-small {
        gap: var(--app-general-spacing-x-small);
    }

    &-center {
        gap: 1em;
        align-items: center;
    }
}

.gridGap-1 {
    grid-gap: 1em;
}

.gridGap-2 {
    grid-gap: 2em;
}

.gap-y-6 {
    row-gap: var(--app-general-spacing-medium); /* 24px */
}

.hover-appear-element {
    display: none !important;
}

*:hover > .hover-appear-element {
    display: block !important;
}

.avatar {
    justify-self: center;
    border-radius: 50%;
    height: 150px;
    width: 150px;
}

.overflowHiddenBorder {
    overflow: hidden;
    padding: 2px;
}

.textSubheader {
    font-size: 1.25rem;
}

.wordBreak {
    word-wrap: break-word; /* IE 5.5-7 */
    white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
    white-space: pre-wrap; /* current browsers */
}

.justify-self-end {
    justify-self: end;
}

.justify-self-center {
    justify-self: center;
}

.justify-self-start {
    justify-self: start;
}

// Protože PrimeNg je NAPROSTO RETARDOVANÝ a nemá k classy pro základní barvy, tak je totok rozkopírováno do vše 3 themes
//.text-danger {
//  color: $dangerButtonTextColor;
//}
//.bg-danger {
//  background-color: $dangerButtonBg;
//}

// svg-icon
svg-icon {
    &.small {
        svg {
            width: 1rem;
            height: 1rem;
        }
    }

    &.x-small {
        svg {
            width: 0.875rem;
            height: 0.875rem;
        }
    }

    &.xx-small {
        svg {
            width: 0.75rem;
            height: 0.75rem;
        }
    }
}
