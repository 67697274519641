@import 'variables';

// border radius
@mixin app-box-corner($radius: var(--app-general-border-radius)) {
    border-radius: $radius;
}

@mixin app-box-corner-top($radius: var(--app-general-border-radius)) {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin app-box-corner-bottom($radius: var(--app-general-border-radius)) {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
}

@mixin app-box-corner-left($radius: var(--app-general-border-radius)) {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
}

@mixin app-box-corner-right($radius: var(--app-general-border-radius)) {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
}

@mixin app-box-corner-none {
    border-radius: 0;
}

@mixin app-box-corner-small() {
    border-radius: var(--app-general-border-radius-small);
}

// flex + grid
@mixin app-flex-column($flex: initial, $overflow: auto) {
    display: flex;
    flex-flow: column;
    flex: $flex;
    overflow: $overflow;
}

@mixin app-flex-row($flex: initial) {
    display: flex;
    flex-flow: row;
    flex: $flex;
}

@mixin app-flex-flow($flow: row) {
    flex-flow: $flow;
}

@mixin app-flex-center() {
    @include app-align-items();
    @include app-justify-content();
}

@mixin app-align-items($align: center) {
    align-items: $align;
}

@mixin app-justify-content($align: center) {
    justify-content: $align;
}

// fonts
@mixin app-font-bold() {
    font-weight: bold;
}

@mixin app-font-semibold() {
    font-weight: 600;
}

@mixin app-font-normal() {
    font-weight: normal;
}

// ellipsis
@mixin app-ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
