.layout-sidebar-dim {
  --d-sidebar-bg-color: #1f2d40;
  --d-sidebar-bg-color-alt: #1f2d40;
  --d-sidebar-border: 1px solid #304562;
  --d-app-name-color: var(--text-color);
  --d-menu-separator-border: 1px solid #304562;
  --d-menuitem-root-text-color: var(--text-color-secondary);
  --d-menuitem-text-color: var(--text-color);
  --d-menuitem-hover-bg: rgba(255, 255, 255, 0.03);
  --d-menuitem-active-bg: #283951;
  --d-menuitem-text-active-color: #ffffff;
  --d-menuitem-focus-shadow: 0 0 0 1px #bbdefb;
}
