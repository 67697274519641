.layout-sidebar-darkgray {
  --d-sidebar-bg-color: #343a40;
  --d-sidebar-bg-color-alt: #24292d;
  --d-sidebar-border: 0 none;
  --d-app-name-color: #ffffff;
  --d-menu-separator-border: 1px solid rgba(255, 255, 255, 0.2);
  --d-menuitem-root-text-color: rgba(255, 255, 255, 0.6);
  --d-menuitem-text-color: rgba(255, 255, 255, 0.8);
  --d-menuitem-hover-bg: #4b545c;
  --d-menuitem-active-bg: #3f474e;
  --d-menuitem-text-active-color: #ffffff;
  --d-menuitem-focus-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
