// p-panelmenu
.p-panelmenu {
    .p-panelmenu-panel {
        margin-bottom: var(--app-general-spacing-small);

        .p-menuitem-text {
            flex: 1;
        }

        .p-panelmenu-header-action {
            @include app-font-bold;
            gap: var(--app-general-spacing-small);
        }

        .p-panelmenu-content {
            border: none;
            padding: 0 !important;
            background-color: transparent;

            .p-panelmenu-root-list {
                padding: 0 !important;

                .p-menuitem {
                    &.panelmenu-selected-option {
                        .p-menuitem-link {
                            .p-menuitem-text {
                                @include app-font-bold;
                                color: var(--primary-color);
                            }
                        }
                    }
                }
            }

            .group {
                text-transform: uppercase;
                margin-top: var(--app-general-spacing);
                font-size: var(--app-general-font-size-x-small);

                &.pointer-events-none {
                    pointer-events: none;
                }

                .p-menuitem-link {
                    padding-right: 0;
                }
            }

            .label {
                .p-menuitem-link {
                    padding-right: 0;
                }

                .p-menuitem-text {
                    width: 100%;
                }

                .p-menuitem-icon,
                .p-icon-wrapper {
                    order: 1;
                }
            }
        }
    }
}

.panelmenu-selected-option {
    background-color: var(--surface-200);
}

.panelmenu-selected-header {
    .p-panelmenu-header-action {
        background-color: var(--surface-200) !important;
        color: var(--primary-color) !important;
    }
}

.panelmenu-label-button-left {
    float: left;
    width: 85%;
}

.panelmenu-label-button-right {
    @include app-flex-row;
    @include app-justify-content(flex-end);

    .p-menuitem-link {
        padding-left: var(--app-general-spacing-small) !important;
        padding-right: var(--app-general-spacing-small) !important;
    }

    .p-menuitem-icon {
        margin: 0 !important;
    }
}

// p-menu
.p-menu {
    padding: var(--app-general-spacing-x-small) !important;

    .p-submenu-header {
        padding: 0.5rem 1rem !important;
    }

    .p-menuitem-link {
        padding: var(--app-general-spacing-small) var(--app-general-spacing) !important;
        border-radius: var(-app-general-border-radius) !important;

        p-checkbox {
            margin-right: var(--app-general-spacing);
        }
    }

    .p-menuitem-separator {
        border-top: 1px solid #e2e8f0;
        margin: var(--app-general-spacing-x-small) 0;
    }
}

// p-button
.p-button {
    &:focus {
        box-shadow: none !important;
    }

    svg-icon {
        margin-right: var(--app-general-spacing-small);
    }

    svg {
        width: 1rem;
        height: 1rem;
        vertical-align: -0.125em;
    }

    &.p-button-sm {
        svg {
            width: 1rem;
            height: 0.95rem;
            vertical-align: -0.175em;
        }
    }

    &.p-button-lg {
        svg {
            width: 1.43rem;
            height: 1.4rem;
            vertical-align: -0.26em;
        }
    }
}

// p-dialog
@media (max-width: $app-mobile-screen-size) {
    .p-dialog {
        max-width: 90% !important;
    }
}

@media (min-width: $app-desktop-screen-size) {
    .p-dialog {
        @for $i from 25 through 90 {
            &.p-dialog-width-#{$i} {
                width: 0% + $i;
                max-width: min(0% + $i, $app-max-modal-width);
            }
        }
    }
}

.p-dialog {
    .p-dialog-header {
        padding: var(--app-general-spacing) var(--app-general-spacing) 0 var(--app-general-spacing) !important;
    }

    .p-dialog-content {
        @include app-box-corner-top;
        padding: var(--app-general-spacing) !important;
    }

    .p-dialog-header + .p-dialog-content {
        @include app-box-corner-top(0);
    }

    .p-dialog-footer {
        padding: 0 var(--app-general-spacing) var(--app-general-spacing) var(--app-general-spacing) !important;
    }

    .p-dialog-footer .dialogButtons-actionButtons {
        @include app-justify-content(space-between);
    }
}

// p-overlay
.p-overlay {
    z-index: 2500 !important;
}

.p-overlaypanel {
    z-index: 2500 !important;
}

// p-datepicker
.p-datepicker {
    z-index: 2500 !important;
}
