@import 'mixins';

.flex {
    @include app-flex-row(initial);

    &-column {
        @include app-flex-column(initial);
    }

    &-center {
        @include app-flex-center();
    }
}

.align-items-center {
    @include app-align-items();
}

.align-items-end {
    @include app-align-items(flex-end);
}

.justify-content-center {
    @include app-justify-content();
}

.justify-content-end {
    @include app-justify-content(flex-end);
}

.justify-content-between {
    @include app-justify-content(space-between);
}

.justify-content-evenly {
    @include app-justify-content(-space-evenly);
}

.flex-1 {
    flex: 1;
}

.gap-large {
    gap: var(--app-general-spacing-large);
}

.gap {
    gap: var(--app-general-spacing);
}

.gap-small {
    gap: var(--app-general-spacing-small);
}

.gap-x-small {
    gap: var(--app-general-spacing-x-small);
}

.gap-xx-small {
    gap: var(--app-general-spacing-xx-small);
}

.gap-xxx-small {
    gap: var(--app-general-spacing-xxx-small);
}

.font-bold {
    @include app-font-bold;
}

.font-semibold {
    @include app-font-semibold;
}

.font-normal {
    @include app-font-normal;
}

.font-italic {
    font-style: italic;
}

.font-size-small {
    font-size: var(--app-general-font-size-small);
}

.font-size-x-small {
    font-size: var(--app-general-font-size-x-small);
}

.font-size-xx-small {
    font-size: var(--app-general-font-size-xx-small);
}

.font-size-large {
    font-size: var(--app-general-font-size-large);
}

.font-size-x-large {
    font-size: var(--app-general-font-size-x-large);
}

.font-size-xx-large {
    font-size: var(--app-general-font-size-xx-large);
}

.text-uppercase {
    text-transform: uppercase;
}

.div-ellipsis {
    @include app-ellipsis;
}

.border-round {
    @include app-box-corner;
}

.border-left-round {
    @include app-box-corner-left;
}

.border-right-round {
    @include app-box-corner-right;
}

.card-shadow {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.w-full {
    width: 100%;
}

.hover-list-item {
    &:hover {
        color: var(--primary-color);
        background-color: var(--highlight-bg);
        cursor: pointer;
    }
}

.selected-list-item {
    color: var(--primary-color);
}

.display-none {
    display: none;
}
