$scale: 14px !default;
$breakpoint: 992px !default;
$sidebarShadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12) !default;

@import 'common-classes';
@import 'primeng-extensions';

@import './fonts';
@import './utils';
@import './typography';
@import './animation';
@import './main';
@import './topbar';
@import './search';
@import './sidebar/sidebar';
@import './content';
@import './footer';
@import './config';
@import './responsive';
